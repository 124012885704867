import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';



import '../Resources/dashboard.css';


function Create_customer(){


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
//saving default values with usestate
const state = useState();

//name
const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);}; const[name_er,setname_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//whatsapp
const[whatsapp,setwhatsapp]=useState();const updatewhatsapp = (event) => { setwhatsapp(event.target.value);}; const[whatsapp_er,setwhatsapp_er]=useState();
//address
const[address,setaddress]=useState();const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
//city
const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);}; const[city_er,setcity_er]=useState();
//cstate
const[cstate,setcstate]=useState();const updatecstate = (event) => { setcstate(event.target.value);}; const[cstate_er,setcstate_er]=useState();
//country
const[country,setcountry]=useState();const updatecountry = (event) => { setcountry(event.target.value);}; const[country_er,setcountry_er]=useState();
//pincode
const[pincode,setpincode]=useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//category
const[category,setcategory]=useState();const updatecategory = (event) => { setcategory(event.target.value);}; const[category_er,setcategory_er]=useState();
//gstin
const[gstin,setgstin]=useState();const updategstin = (event) => { setgstin(event.target.value);}; const[gstin_er,setgstin_er]=useState();
//license_no
const[license_no,setlicense_no]=useState();const updatelicense_no = (event) => { setlicense_no(event.target.value);}; const[license_no_er,setlicense_no_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




  
//******************* ADD CUSTOMER FUNCTION START ********************//
function addcustomer(){

  //Setting Error Variables
setname_er(" ");setemail_er(" ");setcontact_er(" ");setwhatsapp_er(" ");setaddress_er();setcity_er();setcstate_er();setcountry_er();setpincode_er();settype_er();setcategory_er();setgstin_er();setlicense_no_er();seterror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-add-customer", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Name : name,
   Email:email,
   Contact : contact,
   Whatsapp:whatsapp,
   Address:address,
   City:city,
   State:cstate,
   Country:country,
   Pincode:pincode,
   Type:type,
   Category:category,
   Gstin:gstin,
   License_no:license_no


  })
  .then((response) => {


  //Customers Limit Reached
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }
    
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }


      
 else if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
  else if(response.data.validatorerror){
  setname_er(response.data.validatorerror.Name);
  setemail_er(response.data.validatorerror.Email);
  setcontact_er(response.data.validatorerror.Contact);
  setwhatsapp_er(response.data.validatorerror.Whatsapp);
  setaddress_er(response.data.validatorerror.Address);
  setcity_er(response.data.validatorerror.City);
  setcstate_er(response.data.validatorerror.State);  
  setcountry_er(response.data.validatorerror.Country);
  setpincode_er(response.data.validatorerror.Pincode);  
  settype_er(response.data.validatorerror.Type);
  setcategory_er(response.data.validatorerror.Category);
  setgstin_er(response.data.validatorerror.Gstin);
  setlicense_no_er(response.data.validatorerror.License_no);

}
//Invalid Entries
else if(response.data.error==102){ 
  
  setname_er(response.data.name_er);
  setemail_er(response.data.email_er);
  setcontact_er(response.data.contact_er);
  setwhatsapp_er(response.data.whatsapp_er);
  setaddress_er(response.data.address_er);
  setcity_er(response.data.city_er);
  setcstate_er(response.data.state_er);
  setcountry_er(response.data.country_er);
  setpincode_er(response.data.pincode_er);
  settype_er(response.data.type_er);
  setcategory_er(response.data.category_er);
  setgstin_er(response.data.gstin_er);
  setlicense_no_er(response.data.license_no_er);
  seterror_msg("Cannot Add Customer!");  }

 //Email Already Exists
 else if(response.data.error==103){  seterror_msg(response.data.error_msg); } 

   //Transaction Entered Successfully
  else if(response.data.successvar==1){  document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Customer !"); }


  


});

}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD CUSTOMER FUNCTION END ********************//





return(
    <>
                <div class="card" style={{textAlign:"left"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add New Customer</h4>
                    <div class="form-sample">
                      <p class="card-description">add new customer</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Suman Kumar" id="name" value={name} onChange={updatename} />
                              <code>{name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="sumankumar123@gmail.com" id="email" value={email} onChange={updateemail} />
                              <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 84754 00000" id="contact" value={contact} onChange={updatecontact} />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">WhatsApp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 84754 00000" id="whatsapp" value={whatsapp} onChange={updatewhatsapp} />
                              <code>{whatsapp_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea class="form-control" rows="2"  id="address" value={address} onChange={updateaddress} ></textarea>
                        <code>{address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Lucknow" id="city" value={city} onChange={updatecity} />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Uttar Pradesh" id="cstate" value={cstate} onChange={updatecstate} />
                              <code>{cstate_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India"  id="country" value={country} onChange={updatecountry}/>
                              <code>{country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" pincode="420110" id="pincode" value={pincode} onChange={updatepincode} />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular" id="type" value={type} onChange={updatetype} />
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Offline" id="category" value={category} onChange={updatecategory} />
                              <code>{category_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">GSTIN</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="HUGVSTS55AAA" id="gstin" value={gstin} onChange={updategstin} />
                              <code>{gstin_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">License No.</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="MGFS87666" id="license_no" value={license_no} onChange={updatelicense_no} />
                              <code>{license_no_er}</code>
                            </div>
                          </div>
                        </div>
                       
                      </div>
  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addcustomer}>Add Customer</button>
                      
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Create_customer;