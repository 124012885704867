import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import moment from 'moment/moment';

import '../Resources/dashboard.css';


function Create_employee(){


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");


 
//saving default values with usestate
const state = useState();

 //SETTING DATA VARIABLES
 const[employee_code,setemployee_code]=useState();const updateemployee_code = (event) => { setemployee_code(event.target.value);}; const[employee_code_er,setemployee_code_er]=useState();
 const[first_name,setfirst_name]=useState();const updatefirst_name = (event) => { setfirst_name(event.target.value);}; const[first_name_er,setfirst_name_er]=useState();
 const[last_name,setlast_name]=useState();const updatelast_name = (event) => { setlast_name(event.target.value);}; const[last_name_er,setlast_name_er]=useState();
 const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
 const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
 const[gender,setgender]=useState();const updategender = (event) => { setgender(event.target.value);}; const[gender_er,setgender_er]=useState();
 const[date_of_birth,setdate_of_birth]=useState('2100-12-12');const updatedate_of_birth = (event) => { setdate_of_birth(event.target.value);}; const[date_of_birth_er,setdate_of_birth_er]=useState();
 const[date_of_joining,setdate_of_joining]=useState('2100-12-12');const updatedate_of_joining = (event) => { setdate_of_joining(event.target.value);}; const[date_of_joining_er,setdate_of_joining_er]=useState();
 const[address,setaddress]=useState();const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
 const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);}; const[city_er,setcity_er]=useState();
 const[cstate,setcstate]=useState();const updatecstate = (event) => { setcstate(event.target.value);}; const[cstate_er,setcstate_er]=useState();
 const[country,setcountry]=useState();const updatecountry = (event) => { setcountry(event.target.value);}; const[country_er,setcountry_er]=useState();
 const[pincode,setpincode]=useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
 const[position,setposition]=useState();const updateposition = (event) => { setposition(event.target.value);}; const[position_er,setposition_er]=useState();
 const[basic_salary,setbasic_salary]=useState();const updatebasic_salary = (event) => { setbasic_salary(event.target.value);}; const[basic_salary_er,setbasic_salary_er]=useState();
 const[incentives,setincentives]=useState();const updateincentives = (event) => { setincentives(event.target.value);}; const[incentives_er,setincentives_er]=useState();
 const[allowances,setallowances]=useState();const updateallowances = (event) => { setallowances(event.target.value);}; const[allowances_er,setallowances_er]=useState();
 const[provident_fund,setprovident_fund]=useState();const updateprovident_fund = (event) => { setprovident_fund(event.target.value);}; const[provident_fund_er,setprovident_fund_er]=useState();
 const[salary_deductions,setsalary_deductions]=useState();const updatesalary_deductions = (event) => { setsalary_deductions(event.target.value);}; const[salary_deductions_er,setsalary_deductions_er]=useState();
 const[monthly_salary,setmonthly_salary]=useState();const updatemonthly_salary = (event) => { setmonthly_salary(event.target.value);}; const[monthly_salary_er,setmonthly_salary_er]=useState();
 const[yearly_ctc,setyearly_ctc]=useState();const updateyearly_ctc = (event) => { setyearly_ctc(event.target.value);}; const[yearly_ctc_er,setyearly_ctc_er]=useState();
 const[payable_salary,setpayable_salary]=useState();const updatepayable_salary = (event) => { setpayable_salary(event.target.value);}; const[payable_salary_er,setpayable_salary_er]=useState();
 const[mode,setmode]=useState();const updatemode = (event) => { setmode(event.target.value);}; const[mode_er,setmode_er]=useState();
 const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
 const[ifsc_code,setifsc_code]=useState();const updateifsc_code = (event) => { setifsc_code(event.target.value);}; const[ifsc_code_er,setifsc_code_er]=useState();
 const[account_number,setaccount_number]=useState();const updateaccount_number = (event) => { setaccount_number(event.target.value);}; const[account_number_er,setaccount_number_er]=useState();
 const[employee_remarks,setemployee_remarks]=useState();const updateemployee_remarks = (event) => { setemployee_remarks(event.target.value);}; const[employee_remarks_er,setemployee_remarks_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();

 

//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




  
//******************* ADD EMPLYEE FUNCTION START ********************//
function addemployee(){

  //Setting Error Variables
  setemployee_code_er(" ");setfirst_name_er(" ");setlast_name_er(" ");
  setemail_er(" ");setcontact_er(" ");setdate_of_birth_er(" ");setgender_er(" ");setaddress_er(" ");setcity_er(" ");setcstate_er(" ");setcountry_er(" ");setpincode_er(" ");
  setdate_of_joining_er(" ");setbasic_salary_er(" ");setincentives_er(" ");setallowances_er(" ");setprovident_fund_er(" ");setsalary_deductions_er(" ");setmonthly_salary_er(" ");setyearly_ctc_er(" ");
  setmode_er(" ");setbank_name_er(" ");setifsc_code_er(" ");setaccount_number_er(" "); setposition_er(" ");setemployee_remarks_er(" ");seterror_msg(" ");settype_er(" ");


  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((busyid!=null)||(busyid!=" "))&&((counterid!=null)||(counterid!=" "))){
 
 
  axios.post("bt-create-employee", {
   Log_id:logid,
   Token:token,
   Busy_id:busyid,
   Counter_id:counterid,
   Employee_code:employee_code,
   First_name:first_name,
   Last_name:last_name,
   Email:email,
   Contact:contact,
   Gender:gender,
   Date_of_birth:moment(date_of_birth).format('YYYY-MM-DD'),
   Date_of_joining:moment(date_of_joining).format('YYYY-MM-DD'),
   Address:address,
   City:city,
   State:cstate,
   Country:country,
   Pincode:pincode,
   Basic_salary:basic_salary,
   Incentives:incentives,
   Allowances:allowances,
   Provident_fund:provident_fund,
   Salary_deductions:salary_deductions,
   Monthly_salary:monthly_salary,
   Yearly_ctc:yearly_ctc,
   Mode:mode,
   Bank_name:bank_name,
  // Full_name_for_bank:full_name_for_bank,
   Ifsc_code:ifsc_code,
   Account_number:account_number,
   Position:position,
   Employee_remarks:employee_remarks,
   Type:type



  })
  .then((response) => {

  //  console.log(response);

   

  //Customers Limit Reached
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

      
 else if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("BusyId00");
     sessionStorage.removeItem("BusyName00");
     sessionStorage.removeItem("CounterId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
  else if(response.data.validatorerror){
    setemployee_code_er(response.data.validatorerror.Employee_code);
    setfirst_name_er(response.data.validatorerror.First_name);
    setlast_name_er(response.data.validatorerror.Last_name);
    setcontact_er(response.data.validatorerror.Contact);
    setemail_er(response.data.validatorerror.Email);
    setgender_er(response.data.validatorerror.Gender);
    setdate_of_birth_er(response.data.validatorerror.Date_of_birth);
    setaddress_er(response.data.validatorerror.Address);
    setcity_er(response.data.validatorerror.City);
    setcstate_er(response.data.validatorerror.State);
    setcountry_er(response.data.validatorerror.Country);
    setpincode_er(response.data.validatorerror.Pincode);
    setdate_of_joining_er(response.data.validatorerror.Date_of_joining);
    setbasic_salary_er(response.data.validatorerror.Basic_salary);
    setincentives_er(response.data.validatorerror.Incentives);
    setallowances_er(response.data.validatorerror.Allowances);
    setprovident_fund_er(response.data.validatorerror.Provident_fund);
    setsalary_deductions_er(response.data.validatorerror.Salary_deductions);
    setmonthly_salary_er(response.data.validatorerror.Monthly_salary);
    setyearly_ctc_er(response.data.validatorerror.Yearly_ctc);
    setmode_er(response.data.validatorerror.Mode);
    setbank_name_er(response.data.validatorerror.Bank_name);
    setifsc_code_er(response.data.validatorerror.Ifsc_code);
    setaccount_number_er(response.data.validatorerror.Account_number);
    setemployee_remarks_er(response.data.validatorerror.Employee_remarks);
    settype_er(response.data.validatorerror.Type);
    seterror_msg("Cannot Add Employee !");

}
//Invalid Entries
else if(response.data.error==102){ 
  
    setemployee_code_er(response.data.employee_code_er);
    setfirst_name_er(response.data.first_name_er);
    setlast_name_er(response.data.last_name_er);
    setcontact_er(response.data.contact_er);
    setemail_er(response.data.email_er);
    setgender_er(response.data.gender_er);
    setdate_of_birth_er(response.data.date_of_birth_er);
    setaddress_er(response.data.address_er);
    setcity_er(response.data.city_er);
    setcstate_er(response.data.state_er);
    setcountry_er(response.data.country_er);
    setpincode_er(response.data.pincode_er);
    setdate_of_joining_er(response.data.date_of_joining_er);
    setbasic_salary_er(response.data.basic_salary_er);
    setincentives_er(response.data.incentives_er);
    setallowances_er(response.data.allowances_er);
    setprovident_fund_er(response.data.provident_fund_er);
    setmonthly_salary_er(response.data.monthly_salary_er);
    setyearly_ctc_er(response.data.yearly_ctc_er);
    setmode_er(response.data.mode_er);
    setbank_name_er(response.data.bank_name_er);
    setifsc_code_er(response.data.ifsc_code_er);
    setaccount_number_er(response.data.account_number_er);
    setemployee_remarks_er(response.data.employee_remarks_er);
    settype_er(response.data.type_er);
    seterror_msg("Cannot Add Employee!");  }

 //Email Already Exists
 else if(response.data.error==103){  seterror_msg(response.data.error_msg); } 

   //Transaction Entered Successfully
  else if(response.data.successvar==1){  document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Employee !"); }


  


});

}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("BusyId00");
   sessionStorage.removeItem("BusyName00");
   sessionStorage.removeItem("CounterId00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* ADD EMPLOYEE FUNCTION END ********************//





return(
    <>
                <div class="card" style={{textAlign:"left"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add New Employee</h4>
                    <div class="form-sample">
                      <p class="card-description">add new employee</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee_code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="EMP0001" id="employee_code" value={employee_code} onChange={updateemployee_code} />
                              <code>{employee_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Type" id="type" value={type} onChange={updatetype} />
                            <code>{type_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">First Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="First Name" id="first_name" value={first_name} onChange={updatefirst_name} />
                              <code>{first_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Last Name</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Last Name" id="last_name" value={last_name} onChange={updatelast_name}  />
                            <code>{last_name_er}</code>
                            </div>
                          </div>
                        </div>   
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="myemail@gmail.com"  id="email" value={email} onChange={updateemail} />
                              <code>{email_er}</code> 
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 9000000000"  id="contact" value={contact} onChange={updatecontact} />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gender</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}}  id="gender" value={gender} onChange={updategender}>
                              <option value=" ">select gender</option>
                              <option value="male">male</option>
                              <option value="female">female</option>
                              <option value="undisclosed">undisclosed</option>
                              </select>
                              <code>{gender_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date_of_birth" value={date_of_birth} onChange={updatedate_of_birth} />
                              <code>{date_of_birth_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="form-group">
                        <label for="exampleTextarea1">Address</label>
                        <textarea  class="form-control" rows="2" placeholder="My address" id="address" value={address} onChange={updateaddress} />
                        <code>{address_er}</code>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Mumbai" id="city" value={city} onChange={updatecity} />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Maharashtra" id="cstate" value={cstate} onChange={updatecstate} />
                              <code>{cstate_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India" id="country" value={country} onChange={updatecountry} />
                              <code>{country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="100000" id="pincode" value={pincode} onChange={updatepincode} />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <hr/>
                      <h4>Salary Details</h4>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Position</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Branch Head" id="position" value={position} onChange={updateposition} />
                              <code>{position_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Mode</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="mode" value={mode} onChange={updatemode} >
                            <option value=" ">select mode</option>
                            <option value="cash">cash</option>
                            <option value="bank">bank</option>
                            </select>
                            <code>{mode_er}</code>
                            </div>
                          </div>
                        </div>                    
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Joining</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date_of_joining" value={date_of_joining} onChange={updatedate_of_joining} />
                              <code>{date_of_joining_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Basic Salary</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="10000.00" id="basic_salary" value={basic_salary} onChange={updatebasic_salary} />
                            <code>{basic_salary_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incentives</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="incentives" value={incentives} onChange={updateincentives} />
                              <code>{incentives_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Allowances</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="5000.00" id="allowances" value={allowances} onChange={updateallowances} />
                            <code>{allowances_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Provident Fund</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500.00" id="provident_fund" value={provident_fund} onChange={updateprovident_fund} />
                              <code>{provident_fund_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Salary Deductions</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="500.00" id="salary_deductions" value={salary_deductions} onChange={updatesalary_deductions} />
                            <code>{salary_deductions_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Monthly Salary</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000.00" id="monthly_salary" value={monthly_salary} onChange={updatemonthly_salary} />
                              <code>{monthly_salary_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Yearly CTC</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="1000.00" id="yearly_ctc" value={yearly_ctc} onChange={updateyearly_ctc} />
                            <code>{yearly_ctc_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <hr/>
                      <h4>Banking Details</h4>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="SBI Bank Cherital" id="bank_name" value={bank_name} onChange={updatebank_name} />
                              <code>{bank_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">IFSC code</label>
                            <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="SBI9900000" id="ifsc_code" value={ifsc_code} onChange={updateifsc_code} />
                            <code>{ifsc_code_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1200000000000" id="account_number" value={account_number} onChange={updateaccount_number} />
                              <code>{account_number_er}</code>
                            </div>
                          </div>
                        </div>
                     </div>

                   
                      <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="Add your Remarks here" id="employee_remarks" value={employee_remarks} onChange={updateemployee_remarks} />
                        <code>{employee_remarks_er}</code>
                      </div>

  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addemployee}>Add Employee</button>
                      
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Create_employee;